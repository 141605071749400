<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-25 10:52:42
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-10 14:23:35
-->
<template>
  <div class="registerPage">
    <header v-if="!isNext">新用户注册</header>
    <header v-else>完善信息</header>

    <el-form :model="registerForm" :rules="isNextBtn?registerFormRules2:registerFormRules1" ref="registerForm" class="form_box">
      <template v-if="!isNext">
        <el-form-item prop="promotionCode">
          <el-input v-model="registerForm.promotionCode" placeholder="请输入邀请码"></el-input>
        </el-form-item>
        <el-form-item prop="imgCode">
          <el-input v-model="registerForm.imgCode" placeholder="请输入验证码">
            <el-button slot="append" class="graphic-code" @click="getLoginCode">
              <img v-if="codeImg != ''" :src="codeImg" alt="验证码" class="imgCode" />
              <span v-else class="imgCode">点击重新加载</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="registerForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="registerForm.code" placeholder="请输入动态码">
            <template slot="append">
              <el-link type="info" v-if="isOn">({{count}}秒)后重新发送</el-link>
              <el-link type="primary" @click="handleCode" v-else>发送验证码</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="buttons">
          <!-- <el-button type="primary" @click="isNext = true">下一步</el-button> -->
          <el-button type="primary" @click="handleNext">下一步</el-button>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="name">
          <el-input v-model="registerForm.name" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item prop="idNumber">
          <el-input v-model="registerForm.idNumber" placeholder="请输入证件号码"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="registerForm.password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item prop="pass2">
          <el-input type="password" v-model="registerForm.pass2" placeholder="请确认登录密码"></el-input>
        </el-form-item>
        <el-form-item class="buttons">
          <el-button  @click="isNext = false">上一步</el-button>
          <el-button
            type="primary"
            @click="submitForm('registerForm')"
            :loading="loginLoading"
          >{{loginText}}</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import api from "@/api/list";
import { apiList } from "@/api/list";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录密码"));
      } else {
        if (this.registerForm.pass2 !== "") {
          this.$refs.registerForm.validateField("pass2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入登录密码"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loginLoading: false,
      isNextBtn:false,
      loginText: "注册",
      isNext: false,
      count: 60,
      codeImg: "",
      isOn: false,
      registerForm: {
        promotionCode: "",
        mobile: "",
        code: "",
        codeToken: "",
        imgCode: "",
        imgToken: "",
        name: "",
        idNumber: "",
        password: "",
        pass2: "",
      },
      registerFormRules1: {
        promotionCode: [
          { required: true, message: "请输入邀请码", trigger: "blur" },
          { validator: this.validateInputPromotionCode, trigger: "blur" }
        ],
        imgCode: [
          { required: true, message: "请输入验证码!", trigger: "blur" },
          { validator: this.validateInputCode, trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号应该为11位数字",
            trigger: "blur",
          },
          { validator: this.validateMobile, trigger: "blur" },
        ],
      },
      registerFormRules2: {
        promotionCode: [
          { required: true, message: "请输入邀请码", trigger: "blur" },
          { validator: this.validateInputPromotionCode, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号应该为11位数字",
            trigger: "blur",
          },
          { validator: this.validateMobile, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入动态码", trigger: "blur" },
          { validator: this.validateInputMessageCode, trigger: "blur" },
        ],
        imgCode: [
          { required: true, message: "请输入验证码!", trigger: "blur" },
          { validator: this.validateInputCode, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        idNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: this.validateIdNumber, trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        pass2: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted: function () {
    this.getLoginCode();
  },
  watch: {
    codeImg: function () {},
  },
  methods: {
    handleNext() {
      this.isNextBtn = true;
      this.$nextTick(function () {
        this.$refs["registerForm"].validate((valid) => {
          if (valid) {
            this.isNext = true;
          }
        });
      }) 
    },
    getLoginCode() {
      this.registerForm.imgToken = this.getRandomCode(32);
      api.getImgCode({ token: this.registerForm.imgToken }).then(
        (response) => {
          this.codeImg =
            process.env.VUE_APP_BASE_API +
            apiList.getImgCode +
            "?token=" +
            this.registerForm.imgToken;
        },
        (error) => {
          this.codeImg = "";
        }
      );
    },
    getRandomCode(length) {
      if (length > 0) {
        var data = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    validateInputCode(rule, value, callback) {
      api
        .validateCode({ token: this.registerForm.imgToken, code: value })
        .then((response) => {
          if (!value || response.data) {
            callback();
          } else {
            callback("您输入的验证码不正确!");
          }
        });
    },
    //判断邀请码是否有效
    validateInputPromotionCode(rule, value, callback) {
       api
         .validatePromotionCode({code:value })
           .then((response) => {
               if (!value || response.data) {
                   callback();
               } else {
                  callback("您输入的邀请码不正确!");
               }
           });
    },
    //判断手机短信验证码是否正确
    validateInputMessageCode(rule, value, callback) {
       api
           .validateMessageCode({ token: this.registerForm.codeToken, code: value , mobile: this.registerForm.mobile })
           .then((response) => {
               if (!value || response.data) {
                   callback();
               } else {
                   callback("您输入的短信验证码不正确!");
               }
           });
    },
    validateIdNumber(rule, value, callback) {
      api
        .checkId({}, { subUrl: this.registerForm.idNumber + "/idNumber" })
        .then((response) => {
          if (!value || response.data) {
            callback("该证件号码已被注册!");
          } else {
            callback();
          }
        });
    },
    validateMobile(rule, value, callback) {
      api
        .checkId({}, { subUrl: this.registerForm.mobile + "/mobile" })
        .then((response) => {
          if (!value || response.data) {
            callback("该手机号已被注册!");
          } else {
            callback();
          }
        });
    },
    handleCode() {
      this.isNextBtn = false;
      this.$nextTick(function () {
        this.$refs["registerForm"].validate((valid) => {
          if (valid) {  
            api.sendSmsRegister({ mobile: this.registerForm.mobile }).then((res) => {
              if(res.status == 200){
                this.registerForm.codeToken = res.data;
                this.isOn = true;
                let that = this;
                let interval = window.setInterval(function () {
                    --that.count;
                    if (that.count < 0) {
                        that.isOn = false;
                        that.count = 60;
                        window.clearInterval(interval);
                    }
                }, 1000);
              }else{
                this.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "error",
                });
              }
                
            });
          }
        });
      })  
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          this.loginText = "注册中";
          let temp = JSON.parse(JSON.stringify(this.registerForm));
          delete temp.imgCode;
          delete temp.imgToken;

          api
            .register(temp, { method: "post" })
            .then((res) => {
              this.$message({
                showClose: true,
                message: "注册成功",
                type: "success",
              });
              this.$router.push("/login");
              this.loginLoading = false;
              this.loginText = "注册";
            })
            .catch(() => {
              this.loginLoading = false;
              this.loginText = "注册";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.registerPage {
  width: 1210px;
  margin: 20px auto;
  background: url(../../assets/register_bg.png) right bottom no-repeat #fff;
  padding: 60px 130px;
  header {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
}
.form_box {
  width: 294px;
  margin: 0 auto;
  /deep/ .el-link--info {
    .el-link--inner {
      color: #999;
    }
  }
  /deep/ .el-input__inner {
    background: #f3f3f3;
    border-color: #f3f3f3;
  }

  /deep/ .el-input-group__append {
    background: #f3f3f3;
    border: none;
  }

  .graphic-code {
    position: relative;
    top: 7px;
    padding: 0;
  }

  .imgCode {
    display: block;
    height: 38px;
    line-height: 38px;
    min-width: 120px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #999;
  }
}
.buttons {
  /deep/ .el-button {
    width: 48%;
    margin: 42px 2px 12px;

    &:only-child {
      display: block;
      width: 100%;
      margin: 42px 0 12px;
    }

    &.el-button--warning {
      background: #ffbf25;
      border-color: #ffbf25;
    }

    &.el-button--primary {
      background: #5f6eea;
      border-color: #5f6eea;
    }
  }
}

/deep/ input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
}
</style>